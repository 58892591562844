import _interceptor from "../interceptor";
import _UrlBuilder from "../UrlBuilder";
var exports = {};
var interceptor, UrlBuilder;
interceptor = _interceptor;
UrlBuilder = _UrlBuilder;

function startsWith(str, prefix) {
  return str.indexOf(prefix) === 0;
}

function endsWith(str, suffix) {
  return str.lastIndexOf(suffix) + suffix.length === str.length;
}
/**
 * Prefixes the request path with a common value.
 *
 * @param {Client} [client] client to wrap
 * @param {number} [config.prefix] path prefix
 *
 * @returns {Client}
 */


exports = interceptor({
  request: function (request, config) {
    var path;

    if (config.prefix && !new UrlBuilder(request.path).isFullyQualified()) {
      path = config.prefix;

      if (request.path) {
        if (!endsWith(path, "/") && !startsWith(request.path, "/")) {
          // add missing '/' between path sections
          path += "/";
        }

        path += request.path;
      }

      request.path = path;
    }

    return request;
  }
});
export default exports;